/**
 * Created by john on 23/9/16.
 */
'use strict';

/* exported
 zonesCtrl
  */

function zonesCtrl($scope, $state, zones, Restangular, $location, $uibModal) {
  $scope.zones = zones;
  $scope.filters = {};

  const search = $location.search();

  // On init if name is filtered
  if (search.name) {
    $scope.filters.name = search.name;
  }
  if (search.city) {
    $scope.filters.city = search.city;
  }
  /**
   * Paging
   */
  // On init if page is above 1
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.zones.length;
  $scope.pageChanged = function() {
    const limit = 20;
    const offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.zones.getList($scope.filters).then(function(zones) {
      $scope.zones = zones;
    });
  };

  $scope.$watch('currentPage', function(page) {
    const search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });
  $scope.deleteZone = function (zoneId) {
    $uibModal.open({
      templateUrl: 'views/are-you-sure-modal.html',
      controller: 'AreYouSureCtrl',
      resolve: {
        text: null,
        title: [function () { return 'Are you sure?'; }]
      }
    })
    .result
    .then(function(response) {
      if (response) {
        Restangular.one(`v2/zones/${zoneId}`).remove().then(function (deleteRes) {
          $scope.$emit("notify", {
            type: "success",
            title: "Delete zone",
            message: "Zone deleted successfully",
          });
          $state.reload();
        })
      }
    })
  }
  /**
   * Filters
   */

  $scope.$on('filters.name', function(event, data) {
    $scope.filters.name = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.withPagination = true;

    $scope.zones.getList($scope.filters).then(function(zones) {

      $scope.currentPage = 1;
      $scope.zones = zones;
      $scope.totalItems = $scope.zones.meta.total;
    });
  });
  $scope.$on('filters.city', function(event, data) {
    $scope.filters.city = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.withPagination = true;

    $scope.zones.getList($scope.filters).then(function(zones) {

      $scope.currentPage = 1;
      $scope.zones = zones;
      $scope.totalItems = $scope.zones.meta.total;
    });
  });
}


'use strict';

/* exported
  menuZonesCtrl
 */
function menuZonesCtrl($scope, $rootScope, $location) {
  $scope.filters = {};
  // On init if country is selected display it
  const search = $location.search();

  if (search.countryName) {
    $scope.filters.name = search.name;
  }

  $scope.$watch('filters.name', function(name) {
    $rootScope.$broadcast('filters.name', name);

    const search = $location.search();
    search.name = name;
    $location.search(search);
  });
  $scope.$watch('filters.city', function(city) {
    const cityId = city ? city._id : '';
    $rootScope.$broadcast('filters.city', cityId);

    const search = $location.search();
    search.city = cityId;
    $location.search(search);
  });
  $scope.reset = function() {
    $scope.filters = {};
    $location.search({});
  };
}


'use strict';

/* exported
 zoneViewCtrl
 */

function zoneViewCtrl($scope, zone, appConfig) {
  $scope.zone = zone;
}


"use strict";

/* exported
 zoneEditCtrl
 */

function zoneEditCtrl($scope, zone, Restangular, $state) {
  $scope.zone = zone || {};
  $scope.$watch('zone.city', function(city) {
    if ($scope.zone && $scope.zone.city) {
      $scope.zone.country = city.country;
      $scope.zone.countryCode = city.countryCode;
    }
  });
  $scope.save = function () {
    $scope.globalError = null;

    if ($scope.zoneForm.$invalid) {
      $scope.zoneForm.name.$setTouched();
      $scope.zoneForm.slug.$setTouched();
      // $scope.zoneForm.latlng.$setTouched();
      $scope.zoneForm.city.$setTouched();
      $scope.zoneForm.description.$setTouched();

      return;
    }

    const promise = $scope.zone._id
      ? $scope.zone.put()
      : Restangular.all("v2/zones").post($scope.zone);

    promise
      .then(function (zone) {
        $scope.$emit("notify", {
          type: "success",
          title: "Create zone",
          message: "Zone created with success",
        });
        $scope.zone = zone;
        $state.go("root.editZone", { zoneId: zone._id });
      })
      .catch(function (err) {
        $scope.$emit("notify", {
          type: "error",
          title: "Create zone",
          message: "Failed to create zone",
        });

        $scope.globalError = err.data.message;
      });
  };
}
